<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.Relations",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
  </div>
  <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="pills-relation-rollup-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-rollup-filters"
        type="button"
        role="tab"
        aria-selected="true"
        @click="relationTabClick('rollup')"
      >
        {{
          $t("Relations.Rollup", {}, { locale: this.$store.state.activeLang })
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-relation-lookup-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-lookup-filters"
        type="button"
        role="tab"
        aria-selected="false"
        @click="relationTabClick('lookup')"
      >
        {{
          $t("Relations.Lookup", {}, { locale: this.$store.state.activeLang })
        }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-relation-object-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-object-filters"
        type="button"
        role="tab"
        aria-selected="false"
        @click="relationTabClick('object')"
      >
        {{
          $t(
            "Relations.ObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div
      class="tab-pane fade active show"
      id="pills-rollup-filters"
      role="tabpanel"
    >
      <Grid
        v-if="!showLookupGrid && !showObjectGrid"
        :settings="gridRollupSettings"
        :columns="gridRollupColumns"
      />
    </div>
    <div class="tab-pane fade" id="pills-lookup-filters" role="tabpanel">
      <Grid
        v-if="showLookupGrid"
        :settings="gridLookupSettings"
        :columns="gridLookupColumns"
      />
    </div>
    <div class="tab-pane fade" id="pills-object-filters" role="tabpanel">
      <Grid
        v-if="showObjectGrid"
        :settings="gridObjectSettings"
        :columns="gridObjectRelationColumns"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "CustomObjectRelations",
  components: {},
  data() {
    return {
      customObjectId: "",
      gridRollupSettings: {
        action: "CustomObjectRollupRelations",
        requestUrl: String.format(
          "/Lcdp-RelationGetFields?listTypeId=0&fieldTypeId=23"
        ),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "fieldId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        autoOrderProcess: true,
        changeOrderUrl: "",

        isGatewayRequest: true,

        serialNoLink: `#/CustomObject/EditField/__CUSTOMOBJECTPUBLICID__&fieldId=`,

        buttons: [
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditField",
              params: {
                customObjectId: this.$route.params.customObjectId,
                fieldId: "",
              },
            },
          },
        ],
      },
      gridLookupSettings: {
        action: "CustomObjectLookupRelations",
        requestUrl: String.format(
          "/Lcdp-RelationGetFields?listTypeId=1&fieldTypeId=13"
        ),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "fieldId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        autoOrderProcess: true,
        changeOrderUrl: "",

        isGatewayRequest: true,

        serialNoLink: `#/CustomObject/EditField/__CUSTOMOBJECTPUBLICID__&fieldId=`,

        buttons: [
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditField",
              params: {
                customObjectId: this.$route.params.customObjectId,
                fieldId: "",
              },
            },
          },
        ],
      },
      gridObjectSettings: {
        action: "CustomObjectObjectRelations",
        requestUrl: String.format(
          "/Lcdp-RelationGetObjectRelations?listTypeId=2"
        ),
        requestUrlRouteParamNames: [
          { key: "customObjectPublicId", value: "customObjectId" },
        ],
        routeRequiredParameters: [
          {
            key: "objectRelationId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/EditObjectRelation/__CUSTOMOBJECTPUBLICID__&objectRelationId=`,

        allowSearchPanel: true,
        allowDragAndDrop: false,
        allowPaging: true,
        autoOrderProcess: true,
        changeOrderUrl: "",

        isGatewayRequest: true,

        buttons: [
          {
            name: "editViewFilter",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditObjectRelation",
              params: {
                customObjectId: this.$route.params.customObjectId,
                viewFilterId: "",
              },
            },
          },
        ],
      },
      gridRollupColumns: [
        {
          text: this.$t(
            "Relations.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.ObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lookupObjectName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.FieldType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "fieldType",
          type: "string",
          visible: true,
          width: 0,
          template: "customObjectFieldTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      gridLookupColumns: [
        {
          text: this.$t(
            "Relations.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.ObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lookupObjectName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Fields.DefaultValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "defaultValue",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      gridObjectRelationColumns: [
        {
          text: this.$t(
            "Relations.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.FormulaName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "formulaName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.ObjectRelation",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lookupObjectName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Relations.ViewFilter",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "viewFilterName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      showLookupGrid: false,
      showObjectGrid: false,
    };
  },
  methods: {
    relationTabClick(type) {
      this.showLookupGrid = type === "lookup";
      this.showObjectGrid = type === "object";
    },
  },
  mounted() {},
};
</script>
